.header{
    position: fixed;
    top: 0;
    background-color: #D5D7D8;
    z-index: 99; 
    width: 100vw;
    height: 208px;
}

.header-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* fraction*/
    align-items: center;
}

#logo{
    width: 130px;
    height: 103.41px;
}

.logo-grid{
    grid-column: 3;
    justify-self: end;
    padding-right: 30px;
}

.nav-pos-normal{
    width: fit-content;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 84px;

    grid-column: 2;
}

.nav-pos-home{
    position: absolute;
    width: fit-content;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 50px;
}

.navbar{
    display: flex;
    display: -webkit-flex; /* For iPad and iPhone Safari */
    -webkit-flex-wrap: wrap; /* For iPad and iPhone Safari */
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 70px;
}

.nav-text-home{
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    letter-spacing: 0.1em;

    color: #C5C5C5;
    text-decoration: none;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.nav-text{
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    letter-spacing: 0.1em;

    color: #736A63;
    text-decoration: none;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.nav-text.active{
    text-decoration: underline;
    text-underline-offset: 1em;
}

/* Menu */
.menu-grid{
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 60px;
    justify-items: center;
    align-items: center;
    padding: 80px;

    z-index: 20;
}

.menu-container{
    display: none;
}

.menu-home-container.active{
    display: block;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    position: absolute;
    transition: 350s;

    z-index: 20;
}

.menu-container.active{
    display: block;
    height: 100vh;
    width: 100vw;
    background-color: #D5D7D8;
    position: absolute;
    transition: 350s;

    z-index: 20;
}


/* Phone */
@media screen and (max-width: 699px) and (orientation: portrait) {

    .header{
        height: 120px;
    }

    .mobile-header-grid{
        display: grid;

        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 120px;

        z-index: 30;
    }

    /* Logo */
    .logo-grid{
        grid-column: 2;
        z-index: 30;
    }

    #logo{
        width: 85px;
        height: 67.61px;
    }

    /* Header Hamburger */
    .nav-text-home{
        font-size: 25px;
    }
    .nav-text{
        font-size: 25px;
    }

    /* Header Hamburger */
    .hamburger{
        /* Auto layout */

        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;
        padding: 0px;
        gap: 10px;

        margin-left: 40px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-none{
        display: none;
    }

    .hamburger-close{
        display: grid;
        grid-template-columns: 5px 5px 5px;
        padding: 0px;
        gap: 10px;

        margin-left: 40px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-close-none{
        display: none;
    }

    .square{
        height: 5px;
        width: 5px;

        background: #736A63;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) and (orientation: portrait){
    .header{
        height: 140px;
    }

    .mobile-header-grid{
        display: grid;

        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 140px;

        z-index: 30;
    }

    .menu-container.active{
        top: 120px;
    }

    /* Logo */
    .logo-grid{
        grid-column: 2;
        z-index: 30;
    }

    #logo{
        width: 115px;
        height: 91.48px;
    }

    /* Header Hamburger */
    .nav-text-home{
        font-size: 28px;
    }
    .nav-text{
        font-size: 28px;
    }

    /* Header Hamburger */
    .hamburger{
        /* Auto layout */

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        padding: 0px;
        gap: 15px;

        margin-left: 50px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-none{
        display: none;
    }

    .hamburger-close{
        display: grid;
        grid-template-columns: 5px 5px 5px;
        grid-template-rows: 1fr;
        padding: 0px;
        gap: 15px;

        margin-left: 50px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-close-none{
        display: none;
    }

    .square{
        height: 5px;
        width: 5px;

        background: #736A63;
    }
}

@media screen and (max-width: 999px) {
    .header{
        height: 120px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1800px) and (orientation: portrait) {
    .header{
        height: 140px;
    }

    .mobile-header-grid{
        display: grid;

        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 140px;

        z-index: 30;
    }

    /* Logo */
    .logo-grid{
        grid-column: 2;
        z-index: 30;
    }

    #logo{
        width: 115px;
        height: 91.48px;
    }

    /* Header Hamburger */
    .nav-text-home{
        font-size: 30px;
    }
    .nav-text{
        font-size: 30px;
    }

    /* Header Hamburger */
    .hamburger{
        /* Auto layout */

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        padding: 0px;
        gap: 15px;

        margin-left: 50px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-none{
        display: none;
    }

    .hamburger-close{
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto auto auto;
        padding: 0px;
        gap: 15px;

        margin-left: 50px;

        grid-column: 1;
        grid-row: 1;
    }

    .hamburger-close-none{
        display: none;
    }

    .square{
        height: 8px;
        width: 8px;

        background: #736A63;
    }
}

@media screen and (max-width: 699px) and (orientation: landscape){
    .nav-text-home{
        font-size: 16px;
    }

    .nav-pos-home{
        bottom: 5vh;
    }

    #logo{
        position: absolute;
        width: 70px;
        height: 55.68px;
        right: 20px;
        top: 30px;
    }
}

@media screen and (max-width: 699px) and (orientation: landscape){
    .nav-text-home{
        font-size: 25px;
    }

    .nav-pos-home{
        bottom: 5vh;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) and (orientation: landscape){
    .nav-text-home{
        font-size: 18px;
    }

    .nav-pos-home{
        bottom: 5vh;
    }

    #logo{
        width: 100px;
        height: 79.55px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1800px) and (orientation: landscape) {
    .nav-text-home{
        font-size: 18px;
    }

    .nav-pos-home{
        bottom: 5vh;
    }

    #logo{
        width: 115px;
        height: 91.48px;
    }
}


/* Hiding Mobile or Desktop Elemnts */

@media screen and (max-width: 1800px) and (orientation:portrait) {
    .mobile-hide{
        display: none;
    }
}

@media screen and (max-width: 1800px) and (orientation: landscape) {
    .desktop-hide{
        display: none;
    }
}

@media screen and (min-width: 1800px){
    .desktop-hide{
        display: none;
    }
}