.contact-text-cont{
    /* Auto layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0px;
    gap: 30px;

    margin-left: auto;
    margin-right: 0;
}