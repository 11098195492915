#bg{
    object-fit: cover;

    z-index: -1;
}

.home-title{
    display: table;
    margin: 0 auto;
    position: relative;
    height: 49px;
    top: 30vh;

    font-family: proxima-nova, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 35px;
    line-height: 49px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 0.1em;

    color: #C5C5C5;

    z-index: 0;
}

#logo-home{
    position: absolute;
    width: 130px;
    height: 103.41px;
    right: 30px;
    top: 43px;
}

/* Phone */
@media screen and (max-width: 399px) {
    #logo-home{
        position: absolute;
        width: 70px;
        height: 55.68px;
        right: 10px;
        top: 20px;

        grid-column: 2;
    }

    .home-title{
        top: 30px;
        font-size: 28px;
    }
}

/* Tablets */
@media screen and (min-width: 400px) and (max-width: 699px) and (orientation: portrait){
    #logo{
        width: 85px;
        height: 67.61px;
    }

    .home-title{
        top: 50px;
        font-size: 30px;
    }
}

@media screen and (max-width: 699px) and (orientation: landscape){
    #logo-home{
        position: absolute;
        width: 70px;
        height: 55.68px;
        right: 20px;
        top: 30px;
    }

    .home-title{
        top: 110px;
        font-size: 25px;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) and (orientation: portrait){
    #logo-home{
        position: absolute;
        width: 115px;
        height: 91.48px;
        right: 20px;
        top: 30px;

        grid-column: 2;
    }

    .home-title{
        top: 50px;
        font-size: 30px;
    }
}

@media screen and (min-width: 700px) and (max-width: 999px) and (orientation: landscape){
    #logo-home{
        position: absolute;
        width: 100px;
        height: 79.55px;
        right: 20px;
        top: 30px;
    }

    .home-title{
        top: 25vh;
        font-size: 28px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1800px) and (orientation: portrait) {
    #logo-home{
        position: absolute;
        width: 115px;
        height: 91.48px;
        right: 20px;
        top: 30px;

        grid-column: 2;
    }

    .home-title{
        top: 100px;
        font-size: 40px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1800px) and (orientation: landscape) {
    #logo-home{
        position: absolute;
        width: 115px;
        height: 91.48px;
        right: 20px;
        top: 30px;
    }

    .home-title{
        top: 25vh;
        font-size: 30px;
    }
}

@media screen and (max-width: 1800px) and (orientation:portrait) {
    #bg{
        content: url("../assets/L1690019-Elise A4-small.png");

        /* Set rules to fill background */
        height: auto;
        min-height: 100vh;
        width: 100%;

        /* Position */
        position: fixed;
        right: 0px;
        top: 0px;
        transform: scaleX(-1);
    }
}

@media screen and (max-width: 1800px) and (orientation:landscape) {
    #bg{
        content: url("../assets/bank-full.jpg");

        /* Set rules to fill background */
        min-height: 100%;
            
        /* Set up proportionate scaling */
        width: 100%;
        height: auto;

        /* Set up positioning */
        position: fixed;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
    }
}

@media screen and (min-width: 1800px) {
    #bg{
        content: url("../assets/bank-full.jpg");

        /* Set rules to fill background */
        min-height: 100%;
        min-width: 1024px;
            
        /* Set up proportionate scaling */
        width: 100%;
        height: auto;

        /* Set up positioning */
        position: fixed;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
    }
}