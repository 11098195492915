.phil-back{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: #D5D7D8;
    top: 0;
}

.phil-body{
    /* Auto layout */
    display: flex;
    display: -webkit-flex; /* For iPad and iPhone Safari */
    -webkit-flex-wrap: wrap; /* For iPad and iPhone Safari */
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20vw;
    
    left: 0;
    right: 0;
    width: calc(100vw - 200px - 200px);
    margin: 0 auto;

    position: absolute;
    bottom: 42.5px;
}

.phil-text-cont{
    /* Auto layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0px;
    gap: 30px;

    top: 50vh;
}

.phil-grid{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 5vw;
    grid-row-gap: 5vh;
}

.phil-p1{
    grid-column: 1;
}

.phil-p2{
    min-width: max-content;
    grid-column: 2;
    grid-row: 2;
}

@media screen and (max-width: 899px){
    .phil-grid{
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-column-gap: 0;
    }

    .phil-p1{
        grid-row: 1;
    }

    .phil-p2{
        grid-row: 2;
        grid-column: 1;
    }
}

@media screen and (max-width: 449px) {
    .phil-body{
        width: calc(100vw - 50px - 50px);
        gap: 20vw;
    }
}

@media screen and (min-width: 450px) and (max-width: 999px) {
    .phil-body{
        width: calc(100vw - 80px - 80px);
        gap: 20vw;
        overflow: auto;
    }

    .phil-back{
        background-color: #D5D7D8;
        width: 100%;
        display: inline-block;
        left: 0;
        top: 0;
    }
}

@media screen and (max-height: 680px) and (max-width: 999px){
    .phil-body{
        top: 130px;
    }
}

@media screen and (min-width: 450px) and (max-width: 1800px) and (orientation: portrait) {
    .phil-body{
        width: calc(100vw - 70px - 70px);
        gap: 20vw;
    }
}
