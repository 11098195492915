.back-page{
    background-color: #D5D7D8;
    width: 100%;
    display: inline-block;
    top: 0;
}

.page-body{
    /* Auto layout */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 0px;
    gap: 20vw;
    
    left: 0;
    right: 0;
    width: calc(100vw - 184px - 184px);
    margin: 0 auto;
    margin-top: 225px;
    margin-bottom: 42.5px;
} 

.portfolio-unit{
    /* Auto layout */
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 50px;
}

.port-unit-text{
    grid-column: 1;
    grid-row: 1;

    overflow: auto;
}

.port-unit-head{
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.1em;

    color: #736A63;

    text-align: left;
}

.port-unit-body{
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.1em;

    text-align: left;

    white-space: pre-wrap;

    color: #736A63;
}

.port-img{
    width: 100%;
    grid-column: 2;
    grid-row: 1;
}

#top-bench{
    padding: 20px;
    width: 50%;
}

.fake-text{
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.1em;

    text-align: left;

    white-space: pre-wrap;

    color: #D5D7D8;

    grid-column: 1;
    grid-row: 2;
}

@media screen and (max-width: 449px) {
    .port-unit-body{
        font-size: 16px;
    }

    .port-unit-head{
        font-size: 16px;
    }
}

@media screen and (min-width: 450px) and (max-width: 999px) {
    .page-body{
        width: calc(100vw - 50px - 50px);
        gap: 20vw;
        margin-top: 130px;
    }

    .portfolio-unit{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-column-gap: 0px;
        grid-row-gap: 50px
    }

    .port-unit-text{
        grid-row: 2;
        overflow: auto;
    }

    .port-img{
        grid-column: 1;
        grid-row: 1;
    }

    #top-bench{
        padding: 0px;
        width: 65%;
    }
}

@media screen and (max-width: 1800px) and (orientation: portrait) {
    
    .page-body{
        width: calc(100vw - 50px - 50px);
        gap: 20vw;
    }

    .portfolio-unit{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-column-gap: 0px;
        grid-row-gap: 50px
    }

    .port-unit-text{
        grid-row: 2;
        overflow: auto;
    }

    .port-img{
        grid-column: 1;
        grid-row: 1;
    }

    #top-bench{
        padding: 0px;
        width: 65%;
    }
}